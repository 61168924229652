import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

//user
import useClientState from '../client/useClientState';
import ProjectDetailItem from './ProjectDetailItem';

function ProjectDetail(props:PropsValue) {
 

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [clients, setClients] = useState(null)
  const [client_value, setClientValue] = useState(null)
  const {client_list, getClientList} = useClientState()


  const btnSaveOnCookieHandler = () => {
    props.saveOnCookieHandler(item)
  }

  useEffect(() => {
    getClientList(props.token, 1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token])

  useEffect(() => {
    if(props.project){  
      setItem(props.project)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])


  useEffect(() => {
   
    setClients(client_list.map(item => {
      //return(<option value={item.id}>{item.name}</option>)
      return {value:item.id, label:item.name}
    }))

    if(item && item.client){
      setClientValue({value:item.client.id, label:item.client.name})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client_list])
  

  const changeHandler = (e) => {
    setItem(e)
  } 


  const btnClickHandler = () => {
    
    const errors = getErrorMessagte()
    setErrorMessage(null)
    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
 
    if(!item.client_id || item.client_id === 0) errors.push("クライアント名が未入力です。")
    if(!item.name || item.name === "") errors.push("案件名が未入力です。")
    if(!item.project_type || item.project_type === 0) errors.push("区分が未入力です。")
    if(Number(item.project_type) === 1 && !item.category ) errors.push("種類が未入力です。")
    if(!item.closing_date || item.closing_date === "") errors.push("締め日が未入力です。")
    if(!item.report_type || item.report_type === "") errors.push("月報ひな形が未入力です。")
    if(!item.overtime_fee_type || item.overtime_fee_type === "") errors.push("残業設定が未入力です。")
    if(!item.distance_type || item.distance_type === "") errors.push("距離設定が未入力です。")
    if(!item.working_time_type || item.working_time_type === "") errors.push("勤務時間設定が未入力です。")
    //if(Number(item.working_time_type) === 1  && !item.overtime_start_at) errors.push("時間設定開始時刻が未入力です。")
    //if(Number(item.working_time_type) === 1  && !item.overtime_close_at) errors.push("時間設定終了時刻が未入力です。")
    //if(Number(item.working_time_type) === 1  && !item.time_set) errors.push("拘束時間設定が未入力です。")
    return errors
  }

  const changeClientrHandler = (e) => {
    setItem(item.set("client_id", Number(e.value)))
    setClientValue(e)
} 

  const loadOptions = (e) => {
    getClientList(props.token, 1, {name:e})
  }


  return(
    <div className="inner">
       <form >  
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item ? item.id ? "案件情報編集" :"新規案件登録" : null}</h2>
                <Breadcrumbs pages={[new LinkProps("案件登録一覧","/project"),new LinkProps(item ? item.id ? "案件情報編集" :"新規案件登録" : null,"#")]} />
							</div>
							
							<div className="container">
              {item && !item.id && props.has_cookie === true ?<div className="link right" onClick={props.loadFromCookie}><i className="fa-solid fa-rotate-right"></i> 下書きから読み込む</div> : null}
                <div className="column"><p className="container_name">{item ? item.id ? "案件情報編集" :"新規案件登録" : null}</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="input_wrap">
                    {item  ? <ProjectDetailItem token={props.token} project={item} clients={clients} client_value={client_value} is_edit={true}  changeHandler={changeHandler} loadOptions={loadOptions} changeClientrHandler={changeClientrHandler} /> : null}
                  </div>
                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="button_wrap center">
                    {item && !item.id ? <button className="textbutton" type="button"  onClick={btnSaveOnCookieHandler}>下書き保存</button> : null}
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                </div>
                
              </div>

            
				</div>
       </form>
		</div>
    
  )
}

export default ProjectDetail

