import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import useProjectState from '../../../components/project/useProjectState';
import Utile from '../../../utiles/Utile';

interface ProjectSearchBoxProps {

    token:string,
    defaultValue:Number,
    with_sport:Boolean,
  /**
    * changeHandler
    */
   changeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const ProjectSearchBox = ({
  token,
  defaultValue,
  with_sport,
  changeHandler,
  ...props
}: ProjectSearchBoxProps) => { 

  const [managers, setManagers] = useState(null)
  const {project_list, getProjectList} = useProjectState()
  const [default_value, setDefaultValue] = useState(null)
  useEffect(() => {
    getProjectList(token, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    const _list = [{value:null, label:"選択してください"}]
    if(with_sport){
      _list.push({value:-1, label:"スポット"})
    }
    setManagers(_list.concat(project_list.map(item => {
      return {value:item.id, label:item.name}
    }).filter(item => item !== null)))

    const def = project_list.filter(item => item.id ===  Number(defaultValue))
    if(def.length > 0){
      const def_val = {value:Number(def[0].id), label:def[0].name}
      setDefaultValue(def_val)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list])

  const onInputChangeHandler = (e) => {
    if(e){
      getProjectList(token,1,null,{name:e})
    }
  }

  const onChangeHandler = (e) => {
    changeHandler(e)
    setDefaultValue(e)
  }


  return (
    <Select
    placeholder= "選択してください"
    options={managers}
    defaultValue = {default_value}
    value = {default_value}
    onInputChange={onInputChangeHandler}
    noOptionsMessage={()=>""}
    onChange={onChangeHandler}
    styles={Utile.getDefaultSelectStyle(200)}
    />
  );
};

