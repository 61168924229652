import { useState } from 'react';
import ReportAction from './ReportAction';
import config from 'react-global-configuration';
import {format,isDate} from 'date-fns';

function useReportState() {

  
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [unchecked_list, setUnCheckedList] = useState([])
  const [pending_list, setPendingList] = useState([])
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)
  const [checked_at, setCheckedAt] = useState(null)
  const [search_param, setSearchParam] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setTotalNumber(data.headers.get("total-number"))
    setListUpdatedAt((new Date()))
  }
  const listLoadedpubHandler = (data) => {
    setList(data.data)
    setListUpdatedAt((new Date()))
  }

  const unChecklistLoadeddHandler = (data) => {
    setUnCheckedList(data.data)
    setListUpdatedAt((new Date()))
  }

  const pendingListLoadeddHandler = (data) => {
    setPendingList(data.data)
    setListUpdatedAt((new Date()))
  }


  
  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {

    //setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }
  
  const hasCookie = () => {
    return ReportAction.hasCookie()
  }

  const removeCookie = () => {
    ReportAction.removeCookie()
  }

  const saveOnCookie = (item) => {
    ReportAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    ReportAction.loadFromCookie( loadedHandler, errorHandler)
  }
  
  const checkedHandler = () => {
    setCheckedAt(new Date())
  }

  const getList = (token, project_type, page, project_id, driver_id, start_date, end_date, free_word, is_all) => {
  
    const offset = (page - 1)  * 30 
    ReportAction.getList(token, project_type, offset, null, project_id, driver_id, start_date, end_date, free_word, null, is_all, listLoadeddHandler, errorHandler)
  }

  const getListByPartners = (token, start_date, end_date, partner_ids, is_id_only) => {
 
    ReportAction.getListByPartners(token, start_date, end_date,  partner_ids, is_id_only,listLoadeddHandler, errorHandler)
  }

  const getPublicList = ( project_id, driver_id, start_date, end_date) => {
 
    ReportAction.getPublicList( project_id, driver_id, start_date, end_date, listLoadedpubHandler, errorHandler)
  }

  const getUnCheckedList = (token, project_type, page, project_id, driver_id, start_date, end_date, free_word, partner_id) => {
    const offset = 0
    ReportAction.getList(token, project_type, offset, "uncheck", project_id, driver_id, start_date, end_date, free_word, partner_id, null, unChecklistLoadeddHandler, errorHandler)
  }
  const getCheckedList = (token, project_type, page, project_id, driver_id, start_date, end_date, free_word, partner_id) => {
    const offset = (page - 1)  * 30 
    //onst today = new Date()
    ReportAction.getList(token, project_type, offset,  "checked", project_id, driver_id, start_date, end_date, free_word , partner_id , null, listLoadeddHandler, errorHandler)
  }

  const getPendingList = (token, project_type, is_return, project_id = null, driver_id = null, start_date = null, end_date = null, free_word = null ,partner_id ) => {
    ReportAction.getPendingList(token, project_type, is_return, project_id, driver_id, start_date, end_date, free_word, partner_id,   pendingListLoadeddHandler, errorHandler)

  }

  const getListByMonth = (token, project_id, driver_id, year, month, is_all) => {
    console.log("S4")
    ReportAction.getListByMonth(token, project_id, driver_id, year, month, is_all, listLoadeddHandler, errorHandler)
  }

  const getListByPartner = (token, start_date, end_date, partner_id) => {
    ReportAction.getListByPartner(token,  format(start_date,"yyyy-MM-dd"),  format(end_date,"yyyy-MM-dd"), partner_id, listLoadeddHandler, errorHandler)
  }
  const create = (token, project_id, driver_id, item) => {
    
    var  new_item = item.set("driver", null)
      
    new_item = new_item.set("project", null)
    new_item = new_item.set("receipts", null)
    new_item = new_item.set("worked_date", isDate(item.work_start_at) ? format(item.work_start_at,"yyyy-MM-dd") : format(new Date(item.work_start_at),"yyyy-MM-dd"))
    new_item = new_item.set("work_start_at", isDate(item.work_start_at)  ? format(item.work_start_at ,"yyyy-MM-dd HH:mm") : format(new Date(item.work_start_at),"yyyy-MM-dd HH:mm"))
    new_item = new_item.set("work_end_at",isDate(item.work_end_at)  ? format(item.work_end_at ,"yyyy-MM-dd HH:mm") : format(new Date(item.work_end_at),"yyyy-MM-dd HH:mm"))
    new_item = new_item.set("drive_start_at",isDate(item.drive_start_at)  ? format(item.drive_start_at ,"HH:mm:00") : item.drive_start_at)

    ReportAction.create(token, project_id, driver_id, new_item, createdHandler, errorHandler)
  }

  const check = (token, project_id, driver_id, id) => {
    ReportAction.check( token, project_id, driver_id, id,  checkedHandler, errorHandler)
  }

  const uncheck = (token, project_id, driver_id, id) => {
    ReportAction.unCheck( token, project_id, driver_id, id,  checkedHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    ReportAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token, project_id, driver_id, id) => {
    ReportAction.delete(token, id, project_id, driver_id, createdHandler, errorHandler)
  }

  const getReportUrl = (project_id, project_driver_id, id) => {
    return config.get("BASE_PATH") + "/project/" + project_id + "/report/mob/" + project_driver_id + "/" + id
  }


  const getReportForMyDriverUrl = (project_id, project_driver_id, driver_id) => { 
    return config.get("BASE_PATH") + "/project/" + project_id + "/report/mob/" + project_driver_id + "/d/" + driver_id
  }

  const getFee = (unit_price, addition) => {
  
    const addition_cost = Number(addition) ? addition : 0
    const fee = Number(unit_price) + addition_cost  

    return fee ? fee : undefined
  }

  const getHighwayCost = (receipts, addition) => {
    const addition_cost = Number(addition) ? addition : 0
    const total = receipts.filter(receipt => receipt.description === "高速代").map(item => item.price).reduce(function(sum, element){
      return sum + element;
    }, 0);
    
    return total + addition_cost
  }

  const getParkingCost = (receipts, addition) => {
    const addition_cost = Number(addition) ? addition : 0
    const total = receipts.filter(receipt => receipt.description === "駐車場代").map(item => item.price).reduce(function(sum, element){
      return sum + element;
    }, 0);
    
    return total + addition_cost
  }

  const getOtherCost = (receipts, addition) => {
    const addition_cost = Number(addition) ? addition : 0
    const total = receipts.filter(receipt => receipt.description === "その他").map(item => item.price).reduce(function(sum, element){
      return sum + element;
    }, 0);
    
    return total + addition_cost
  }


  const getTotalCost = (receipts) => {
    const total = receipts.map(item => item.price).reduce(function(sum, element){
      return sum + element;
    }, 0);
    
    return total
  }


  const saveSearchParams= (item) => {
    ReportAction.saveSearchParams(item)
  }

  const loadSearchParams = () => {
    setSearchParam(ReportAction.loadSearchParams())
    return ReportAction.loadSearchParams()
  }
  



  



  return { report:item, updated_report:item, report_list:list, report_unchecked_list:unchecked_list, list_updated_at:list_updated_at, pending_list:pending_list,  report_error:error, report_deleted:created, report_created:created, report_total_number:total_number, checked_at:checked_at,
    report_search_param:search_param,
    getReportList:getList, getReportListByPartners:getListByPartners, getReportListByPartner:getListByPartner, getPublicReportList:getPublicList, getPendingReportList:getPendingList, getReportListByMonth:getListByMonth, getReport:getDetail, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, hasCookie:hasCookie, removeCookie:removeCookie, createReport:create, deleteReport:deleteItem,
    getReportUrl, getReportForMyDriverUrl, check, uncheck, getUnCheckedList, getCheckedList, getHighwayCost,getFee, getParkingCost, getTotalCost, getOtherCost, saveSearchParams, loadSearchParams};
}

export default useReportState