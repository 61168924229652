
const config = {
  VERSION:  "0.0.1",
  MODE: process.env.REACT_APP_MODE ? process.env.REACT_APP_MODE : "local",
  CLIENT_ID: "U1VXT6ILURGElBAoTie7Lgij",
  CLIENT_SECRET: "4VNQacuFCOcSOmfWN7fXRPkCmif97HhtfoFKguqAwXzXaxBa",
  MAIL_PATTERN : /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,

  BASE_PATH:     process.env.REACT_APP_MODE === "production" ? 'https://app.lightcargo-report.com' :  process.env.REACT_APP_MODE === "development"  ?  'https://hound.v2008.coreserver.jp' :  process.env.REACT_APP_MODE === "mock"  ?  'http://localhost:3000' : 'http://localhost:3000',

  USER_TYPE_finance :"管理者",
  USER_TYPE_manager: "使用者",
  USER_FINANCE :"finance",
  USER_PARTNER :"partner",
  USER_MNANAGER:"manager",


  EDIT:"更新",
  REGIST:"登録",

  SEARCH_LIMIT:20,

  API_PATH:                 process.env.REACT_APP_MODE === "production" ? 'https://api.lightcargo-report.com' :  process.env.REACT_APP_MODE === "development"  ?  'https://hnjapi.cowzow.jp' :  process.env.REACT_APP_MODE === "mock"  ?  '/mock' : 'http://127.0.0.1:5000',
  GOOGLE_ID:               process.env.REACT_APP_MODE === "production" ? '1094878753666-fmpr4u9k4lbd9csghqlcqa90ut4cib3m.apps.googleusercontent.com' :  process.env.REACT_APP_MODE === "development"  ?  '206825303325-ud06vuqv38rov8s9cij5f47i5n4hu69v.apps.googleusercontent.com' :  process.env.REACT_APP_MODE === "mock"  ?  '/mock' : '206825303325-ud06vuqv38rov8s9cij5f47i5n4hu69v.apps.googleusercontent.com',
  GOOGLE_SECRET:               process.env.REACT_APP_MODE === "production" ? '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' :  process.env.REACT_APP_MODE === "development"  ?  '206825303325-ud06vuqv38rov8s9cij5f47i5n4hu69v.apps.googleusercontent.com' :  process.env.REACT_APP_MODE === "mock"  ?  '/mock' : '206825303325-ud06vuqv38rov8s9cij5f47i5n4hu69v.apps.googleusercontent.com',

  API_AUTH:                 process.env.REACT_APP_MODE === "mock"  ? "/profile.json" : "/oauth/token",
  API_PROFILE:              process.env.REACT_APP_MODE === "mock"  ? "/profile.json" : "/profile/me",
  API_USER:                 process.env.REACT_APP_MODE === "mock"  ? "/user.json" : "/users/",
  API_DRIVER:               process.env.REACT_APP_MODE === "mock"  ? "/driver.json" : "/drivers/",
  API_PARTNER:              process.env.REACT_APP_MODE === "mock"  ? "/partner.json" : "/partners/",
  API_CLIENT:               process.env.REACT_APP_MODE === "mock"  ? "/clients.json" : "/clients/",
  API_PROJECT:              process.env.REACT_APP_MODE === "mock"  ? "/projects.json" : "/projects/",
  API_REPORT:               process.env.REACT_APP_MODE === "mock"  ? "/reports.json" : "/reports/",
  API_MONTHLY_REPORT:       process.env.REACT_APP_MODE === "mock"  ? "/mreports.json" : "/monthly_reports/",
  API_MONTHLY_PAYMENTS:     process.env.REACT_APP_MODE === "mock"  ? "/mreports.json" : "/monthly_payments/",
  API_MEMO:                 process.env.REACT_APP_MODE === "mock"  ? "/memo.json" : "/memos/", 
  PREFS:[ 
    {label:"北海道", value:1},
    {label:"青森県", value:2},
    {label:"岩手県", value:3},
    {label:"宮城県", value:4},
    {label:"秋田県", value:5},
    {label:"山形県", value:6},
    {label:"福島県", value:7},
    {label:"茨城県", value:8},
    {label:"栃木県", value:9},
    {label:"群馬県", value:10},
    {label:"埼玉県", value:11},
    {label:"千葉県", value:12},
    {label:"東京都", value:13},
    {label:"神奈川県", value:14},
    {label:"新潟県", value:15},
    {label:"富山県", value:16},
    {label:"石川県", value:17},
    {label:"福井県", value:18},
    {label:"山梨県", value:19},
    {label:"長野県", value:20},
    {label:"岐阜県", value:21},
    {label:"静岡県", value:22},
    {label:"愛知県", value:23},
    {label:"三重県", value:24},
    {label:"滋賀県", value:25},
    {label:"京都府", value:26},
    {label:"大阪府", value:27},
    {label:"兵庫県", value:28},
    {label:"奈良県", value:29},
    {label:"和歌山県", value:30},
    {label:"鳥取県", value:31},
    {label:"島根県", value:32},
    {label:"岡山県", value:33},
    {label:"広島県", value:34},
    {label:"山口県", value:35},
    {label:"徳島県", value:36},
    {label:"香川県", value:37},
    {label:"愛媛県", value:38},
    {label:"高知県", value:39},
    {label:"福岡県", value:40},
    {label:"佐賀県", value:41},
    {label:"長崎県", value:42},
    {label:"熊本県", value:43},
    {label:"大分県", value:44},
    {label:"宮崎県", value:45},
    {label:"鹿児島県", value:46},
    {label:"沖縄県", value:47},
  ],


  COMMISION:[
    {label:"0%", value:0},
    {label:"7.5%", value:7.5},
    {label:"10%", value:10},
  
  ],
  PAYMENT_TYPE:[
    {label:"翌々月末払い", value:10},
    {label:"翌々月25日払い", value:9},
    {label:"翌々月20日払い", value:8},
    {label:"翌々月15日払い", value:7},
    {label:"翌々月10日払い", value:6},
    {label:"翌々月5日払い", value:11},
    {label:"翌月末払い", value:5},
    {label:"翌月25日払い", value:4},
    {label:"翌月20日払い", value:3},
    {label:"翌月15日払い", value:2},
    {label:"翌月10日払い", value:1}
  ],
  CLIENT_CLOSING_DATE_TYPE:[
    {label:"5日締め", value:1},
    {label:"10日締め", value:2},
    {label:"15日締め", value:3},
    {label:"20日締め", value:4},
    {label:"25日締め", value:5},
    {label:"月末締め", value:6},
  ],
  CLIENT_COMMISION:[
    {label:"0%", value:1},
    {label:"5%", value:2},
    {label:"7.5%", value:3},
    {label:"10%", value:4},
    {label:"15%", value:5},
    {label:"20%", value:6}
  ],
  CLIENT_PAYMENT_DAY_TYPE:[

    {label:"翌月10日払い", value:1},
    {label:"翌月15日払い", value:2},
    {label:"翌月20日払い", value:3},
    {label:"翌月25日払い", value:4},
    {label:"翌月末払い", value:5},
    {label:"翌々月5日払い", value:11},
    {label:"翌々月10日払い", value:6},
    {label:"翌々月15日払い", value:7},
    {label:"翌々月20日払い", value:8},
    {label:"翌々月25日払い", value:9},
    {label:"翌々月末払い", value:10},
  ],

  PROJECT_TYPE:[
    {label:"定期", value:1},
    {label:"スポット", value:2},
  ],

  TAX_INCLUDE:[
    {label:"税込", value:1},
    {label:"税別", value:2},
  ],


  REPORT_TYPE:[
    {label:"デフォルト", value:1, project_categories:[1,2,3]},
    {label:"特別フォーマット1", value:2, project_categories:[1]},
    {label:"ヤマト宅配月報", value:3, project_categories:[4]},
    {label:"郵政", value:4, project_categories:[5]},
    {label:"歩合", value:6, project_categories:[6]}
  ],

  PROJECT_CATEGORY:[
    {label:"企業配送用", value:1},
    {label:"チャーター配送用", value:2},
    {label:"Amazon個人宅配用", value:3},
    {label:"ヤマト運輸個人宅配用", value:4},
    {label:"郵政", value:5},
    {label:"歩合", value:6}
  ],
  PROJECT_SPOT_CATEGORY:[
    {label:"チャーター配送用", value:1},
    {label:"個人宅配用", value:2},
    {label:"企業配送用", value:3}
  ],
  PROJECT_CLOSING_DATE_TYPE:[
    {label:"月末締め", value:1},
    {label:"25日締め", value:2},
    {label:"20日締め", value:3},
    {label:"15日締め", value:4},
  ],
  PROJECT_OVERTIME_TYPE:[
    {label:"なし", value:100},
    {label:"1分単位（1分以上で1分単位計算）", value:1},
    {label:"5分単位「5分以上で5分単位計算」", value:2},
    {label:"10分単位「10分以上で10分単位計算」", value:3},
    {label:"15分単位①「5分以上で15分単位計算」", value:12},
    {label:"15分単位②「10分以上で15分単位計算」", value:4}, 
    {label:"15分単位③「15分以上で15分単位計算」", value:5},
    {label:"30分単位①「1分以上で30分単位計算」", value:6},
    {label:"30分単位②「16分以上で30分単位計算」", value:7}, 
    {label:"30分単位③「20分以上で30分単位計算」", value:8},
    {label:"30分単位④「21分以上で30分単位計算」", value:9},
    {label:"30分単位⑤「30分以上で30分単位計算」", value:10},
    {label:"60分単位「60分以上で60分単位計算」", value:11},
  ],
  PROJECT_DISTANCE_TYPE:[
    {label:"なし", value:1},
    {label:"31km以上計算", value:3},
    {label:"41km以上計算", value:4},
    {label:"51km以上計算", value:5},
    {label:"61km以上計算", value:6},
    {label:"71km以上計算", value:7},
    {label:"81km以上計算", value:8},
    {label:"91km以上計算", value:9},
    {label:"101km以上計算", value:10},
    {label:"111km以上計算", value:11},
    {label:"121km以上計算", value:12},
    {label:"131km以上計算", value:13},
    {label:"141km以上計算", value:14},
    {label:"151km以上計算", value:15}
  ],
  PROJECT_WORKING_TIME_TYPE:[
    {label:"時間設定", value:1},
    {label:"拘束時間", value:2},
    {label:"なし", value:3}
  ],



  TATEKAE_TYPE:[
    {label:"高速代", value:"高速代"},
    {label:"駐車場代", value:"駐車場代"},
    {label:"その他", value:"その他"}
  ],

  PROJECT_DRIVER_WORKING_TYPE:[
    {label:"土日休み", value:1},
    {label:"土日祝休み", value:2},
    {label:"シフト制", value:3},
    {label:"フリー", value:4},
    {label:"市場カレンダー", value:5},
    {label:"365日", value:6},
    {label:"その他", value:7}
  ],


  REPORT_GENDER:[
    {label:"男性", value:1},
    {label:"女性", value:2}
  ],

  REPORT_WAITING_HOUR:[
    {label:"0時間", value:"0"},
    {label:"1時間", value:"1"},
    {label:"2時間", value:"2"},
    {label:"3時間", value:"3"},
    {label:"4時間", value:"4"},
    {label:"5時間", value:"5"},
    {label:"6時間", value:"6"},
    {label:"7時間", value:"7"},
    {label:"8時間", value:"8"},
    {label:"9時間", value:"9"},
    {label:"10時間", value:"10"}
  ],
  REPORT_WAITING_MIN:[
    {label:"0分", value:"0"},
    {label:"15分", value:"15"},
    {label:"30分", value:"30"},
    {label:"45分", value:"45"}
  ],
  ACCOUNT_TYPE:[
    {label:"普通", value:"普通"},
    {label:"当座", value:"当座"},
  ],


}   


export default config;
