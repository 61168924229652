import React, {useEffect} from 'react';
import PropTypes from 'prop-types';


import './pageing.css';


export const Pageing = ({total_number,current_page, path,limit,additional_params}) => {

  useEffect(() => {
    console.log(total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total_number])


  const page_number = Math.ceil(total_number / limit)

  const params =  "&" + additional_params.join("&")
  
  return (
  <div className="pageing">
		{Number(current_page) !== 1 ? <a href={path + "?page=" + (Number(current_page) - 1) + params} className="prev"> </a> : null }
		  <div className="pages">
        {
          (new Array(page_number)).fill(0).map((i,index) => {
           
            return(
              <a href={path + "?page=" + (index + 1) + params}  key={"page" + index} className={Number(current_page) === (index+1) ? "current" : null} >{index + 1}</a>
            )
          })
        }
			 				
			</div>
		{Number(current_page)  !== page_number  ? <a href={path + "?page=" + (Number(current_page) + 1) + params} className="next"> </a> : null}
	</div>
)};

Pageing.propTypes = {
  total_munber:PropTypes.number,
  current_page:PropTypes.number,
  path:PropTypes.string,
  limit:PropTypes.number,
  additional_params:PropTypes.array
  /** onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired, **/
};

Pageing.defaultProps = {
  total_number: 1,
  current_page:1,
  limit:10,
  path:"",
  additional_params:[]

};


/**
 *  <a href="" className="current">1</a>
				
				<a href="">3</a>				
 */