import { useState } from 'react';
import DriverAction from './DriverAction';
import config from 'react-global-configuration';


function useDriverState() {

  const [limit, setLimit] = useState(config.get("SEARCH_LIMIT"))
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setTotalNumber(data.headers.get("total-number"))
    setListUpdatedAt(new Date())

  }
  const loadedHandler = (data) => {
   
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }


  const errorHandler = (data) => {
    setError(data)
  }

  const hasCookie = () => {
    return DriverAction.hasCookie()
  }
  const saveOnCookie = (item) => {
    DriverAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    DriverAction.loadFromCookie( loadedHandler, errorHandler)
  }
  
  const removeCookie = () => {
    DriverAction.removeCookie()
  }


  const getListAll = (token) => {
    DriverAction.getList(token, 0, 20, null, null, null, null, null, null, listLoadeddHandler, errorHandler)
  }


  const getList = (token, page, partner_id = null, item = null, with_deleted = null, with_spot = null, start_date = null, end_date = null) => {
    const offset = (page - 1)  * limit 
    DriverAction.getList(token, offset, limit, partner_id, item, with_deleted, with_spot, start_date, end_date, listLoadeddHandler, errorHandler)
  }

  const create = (token, item) => {
    DriverAction.create(token, item, createdHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    DriverAction.getDetail(token, id, loadedHandler, errorHandler)
  }
  const getDetailInPublic = ( id) => {
    DriverAction.getDetailInPublic( id, loadedHandler, errorHandler)
  }
  
  const deleteItem = (token, id) => {
    DriverAction.delete(token, id, createdHandler, errorHandler)
  }



  return { driver:item, driver_list:list, list_updated_at:list_updated_at, driver_error:error, driver_deleted:created, driver_created:created, driver_total_number:total_number,driver_search_limit:limit,
     getDriverList:getList,getDriverListAll:getListAll, getDriver:getDetail, getDriverDetailInPublic:getDetailInPublic, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie,removeCookie:removeCookie, hasCookie:hasCookie, createDriver:create, deleteDriver:deleteItem,
     setDriverSerachLimit:setLimit};
}

export default useDriverState