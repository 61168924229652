import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';

import ReportMobDetail from "./ReportMobDetail"
import ReportProps from './ReportProps';
import useReportState from './useReportState';
import useProjectState from '../project/useProjectState';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import Receipt from '../../receipt/Receipt';
import useReceiptState from '../../receipt/useReceiptState';
import useDriverState from '../drivers/useDriverState';
import Driver from '../drivers/Driver';

function ReportMobDetailApp(props:PropsValue) {
  const token = null
  const { id } = useParams();
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  const { driver_id } = useParams();
  const { year } = useParams();
  const { month } = useParams();
  const { day } = useParams();


  const [upload_receipts, setReceiptsList] = useState([])

  const [item, setItem] = useState(null)
  const [receipts, setReceipts] = useState([])
  const {report, report_created, createReport, getReport, hasCookie, removeCookie, loadFromCookie} = useReportState()
  const {project, getProject} = useProjectState()
  const {project_driver, getProjectDriver} = useProjectDriverState()
  const {receipt_created, createReceipt} = useReceiptState()
  const {driver,getDriverDetailInPublic} = useDriverState()

  useEffect(() => {
 
    getProject(token, project_id)
    getProjectDriver(token, project_id, project_driver_id)
    if(hasCookie()){
      loadFromCookie()
    }else{
      if(id === "new" || id === "d"){
        
        if(year && month && day){
          setItem(new ReportProps({
            worked_date:new Date(year, month -1 , day),
            work_start_at:new Date(year, month - 1, day),
            work_end_at:new Date(year, month - 1, day)
          }))
        }else{
          setItem(new ReportProps())
        }
        setReceipts([new Receipt()])
      }else{
        getReport(token, id)
      }
    }
   
    if(driver_id){
      getDriverDetailInPublic(driver_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {

    if(report_created && report_created.id){
      removeCookie()    
      if(upload_receipts && upload_receipts.length > 0){
        if(upload_receipts[0].price){
          createReceipt(report_created.id, upload_receipts)
        }else{
          window.location.href = "/project/report/success/mob"
        }
          
      }
     
     // window.location.href = "/project/report/success/mob"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_created])

  

  useEffect(() => {

    if(receipt_created){
      window.location.href = "/project/report/success/mob"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[receipt_created])




  useEffect(() => {

    if(driver){
      var new_item = item.set("driver_name", driver.name )
      setItem(new_item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])


  useEffect(() => {

    if(report){
      console.log("repo")
      var _repo = new ReportProps(report)
      setItem(_repo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report])

  


  const updateHandler = (_item, _receipt) => {
    setReceiptsList(_receipt)
    const driver_id = driver ? driver.uid : project_driver.driver.uid
    createReport(token,project_id, driver_id  , _item)
  }
  
  return(
    <div >
      <header>
					<div className="logo">
           軽貨物月報システム
					</div>
	  	</header>
      <ReportMobDetail project={project} driver={driver ? new Driver(driver) : null} project_driver={project_driver} report={item} receipts={receipts} updateHandler={updateHandler}/>
    </div>
     
  )
}

export default ReportMobDetailApp

