import { useState } from 'react';
import CompanyAction from './CompanyAction';

function useCompanytState() {

  
  const [error, setError] = useState(null)  
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)


  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const loadedHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const getMyDetail = (token) => {
    CompanyAction.getMyDetail(token, loadedHandler, errorHandler)
  }
  
  const update = (token, item) => {
    console.log(item  )
    CompanyAction.update(token, item, createdHandler, errorHandler)
  }




  return { company:item, company_created:created, company_error:error,
    updateCompany:update,
    getMyCopmpanyDetail:getMyDetail
  };
}

export default useCompanytState